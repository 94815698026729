import { PipeModule } from './components/shares/pipe/pipe.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { DEFAULT_TIMEOUT, TimeoutInterceptor} from './interceptors/timeout.interceptor';
import { NavigationComponent } from './components/general/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { SmoothScrollModule } from './directives/smooth-scroll/smooth-scroll.module';
import { MatNativeDateModule } from '@angular/material/core';
import { SmoothScrollbarModule } from './components/shares/smooth-scrollbar/smooth-scrollbar.module';
import { NavMenuComponent } from './components/general/nav-menu/nav-menu.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MemberPagination } from './classes/custom-pagination';
import { UserImageModule } from './components/shares/user-image/user-image.module';
import { MatTreeModule } from '@angular/material/tree';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ShareModule } from './components/shares/share.module';
import { CallbackComponent } from './components/callback/callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LoginModule } from './components/login/login.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [ AppComponent, NavigationComponent, NavMenuComponent, CallbackComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SmoothScrollModule,
    HttpClientModule,
    MatSidenavModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatSnackBarModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    MatTreeModule,
    MatTabsModule,
    MatDividerModule,
    ShareModule,
    UserImageModule,
    MatDialogModule,
    LoginModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LayoutModule,
    SmoothScrollbarModule,
    MatToolbarModule,
    MatListModule,
    PipeModule,
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 36000000 }],
    {
      provide: MatPaginatorIntl,
      useClass: MemberPagination,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
