import { PermanentLossAssessmentModule } from './components/permanent-loss-assessment/permanent-loss-assessment.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './components/general/navigation/navigation.component';
import { AuthGuard } from './guards/auth.guard';
import { CallbackComponent } from './components/callback/callback.component';
import { EXTENDED_ROUTE_PMS } from './models/enums/route-permission';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        data: {},
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'user-admin',
        data: {},
        loadChildren: () =>
          import('./components/user-admin/user-admin.module').then(
            (m) => m.UserAdminModule
          ),
      },
      {
        path: 'accident-request',
        data: {},
        loadChildren: () =>
          import('./components/primary-info/primary-info.module').then(
            (m) => m.PrimaryInfoModule
          ),
      },
      {
        path: 'inspection',
        data: {
          permissions: [
            ...EXTENDED_ROUTE_PMS.INSPECTION_REQUEST,
            ...EXTENDED_ROUTE_PMS.INSPECTION_RESULT,
          ],
        },
        loadChildren: () =>
          import('./components/inspection/inspection.module').then(
            (m) => m.InspectionModule
          ),
      },
      {
        path: 'temp-wi-victim',
        data: {},
        loadChildren: () =>
          import('./components/temporary-info/temporary-info.module').then(
            (m) => m.TemporaryInfoModule
          ),
      },
      {
        path: 'retreatment-injury',
        data: {},
        loadChildren: () =>
          import(
            './components/retreatment-injury/retreatment-injury.module'
          ).then((m) => m.RetreatmentInjuryModule),
      },
      {
        path: 'claim-report',
        data: {},
        loadChildren: () =>
          import('./components/claim-report/claim-report.module').then(
            (m) => m.ClaimReportModule
          ),
      },
      {
        path: 'victim-benifit',
        data: {},
        loadChildren: () =>
          import(
            './components/victim-daily-income-calculate/victim-daily-income-calculate.module'
          ).then((m) => m.VictimBenefitModule),
      },
      {
        path: 'claim-request-hc',
        data: {},
        loadChildren: () =>
          import('./components/claim-request/claim-request.module').then(
            (m) => m.ClaimRequestModule
          ),
      },
      {
        path: 'perm-loss-ass-req',
        data: {},
        loadChildren: () =>
          import('./components/permanent-loss-assessment/permanent-loss-assessment.module').then(
            (m) => m.PermanentLossAssessmentModule
          ),
      },
      {
        path: 'claim-request',
        data: {},
        loadChildren: () =>
          import('./components/claim-request/claim-request.module').then(
            (m) => m.ClaimRequestModule
          ),
      },

      // {
      //   path: 'claim-request/health-care',
      //   data: {
      //     type: CLAIM_TYPE.MEDICAL,
      //     title: 'menu.ben_cal.ben_treatment'
      //   },
      //   loadChildren: () =>import('./components/claim-request-form/claim-request-form.module').then((m) => m.ClaimRequestFormModule),
      // },
      // {
      //   path: 'claim-request/temporary',
      //   data: {
      //     type: CLAIM_TYPE.TEMPORARY,
      //     title: 'menu.ben_cal.ben_temporary'
      //   },
      //   loadChildren: () =>import('./components/claim-request-form/claim-request-form.module').then((m) => m.ClaimRequestFormModule),
      // },
      // {
      //   path: 'claim-request/permanent',
      //   data: {
      //     type: CLAIM_TYPE.PERMINENT,
      //     title: 'menu.ben_cal.ben_permanent'
      //   },
      //   loadChildren: () =>import('./components/claim-request-form/claim-request-form.module').then((m) => m.ClaimRequestFormModule),
      // },
      // {
      //   path: 'claim-request/allowance',
      //   data: {
      //     type: CLAIM_TYPE.ALLOWANCE,
      //     title: 'menu.ben_cal.ben_cremental'
      //   },
      //   loadChildren: () =>import('./components/claim-request-form/claim-request-form.module').then((m) => m.ClaimRequestFormModule),
      // },
      // {
      //   path: 'claim-request/survival',
      //   data: {
      //     type: CLAIM_TYPE.SURVIVAL,
      //     title: 'menu.ben_cal.ben_survival'
      //   },
      //   loadChildren: () =>import('./components/claim-request-form/claim-request-form.module').then((m) => m.ClaimRequestFormModule),
      // },
      {
        path: 'benifit-calculate',
        data: {},
        loadChildren: () =>
          import(
            './components/occupation-risk-calculate/occupation-risk-calculate.module'
          ).then((m) => m.OccupationRiskCalculateModule),
      },
      {
        path: 'print',
        data: {},
        loadChildren: () =>
          import('./components/print/print.module').then((m) => m.PrintModule),
      },
      {
        path: 'benefit-approval',
        data: {},
        loadChildren: () =>
          import('./components/benefit-approval/benefit-approval.module').then(
            (m) => m.BenefitApprovalModule
          ),
      },
      {
        path: 'monthly-pension-payment',
        data: {},
        loadChildren: () =>
          import('./components/monthly-pension-payment/monthly-pension-payment.module').then(
            (m) => m.MonthlyPensionPaymentModule
          ),
      },
      {
        path: 'benefit-order',
        data: {},
        loadChildren: () =>
          import('./components/benefit-order/benefit-order.module').then(
            (m) => m.BenefitOrderModule
          ),
      },
      {
        path: 'benefit-non-occupate',
        data: {},
        loadChildren: () =>
          import(
            './components/benefit-non-occupate/benefit-non-occupate.module'
          ).then((m) => m.BenefitNonOccupateModule),
      },
      {
        path: 'shipping-benefit',
        data: {},
        loadChildren: () =>
          import('./components/shipping-benefit/shipping-benefit.module').then(
            (m) => m.ShippingBenefitModule
          ),
      },
      {
        path: 'work-activity',
        data: {},
        loadChildren: () =>
          import(
            './components/work-activity-data/work-activity-data.module'
          ).then((m) => m.WorkActivityDataModule),
      },
      {
        path: 'maternity-doc',
        data: {},
        loadChildren: () =>
          import(
            './components/maternity-scan-doc/maternity-scan-doc.module'
          ).then((m) => m.MaternityScanDocModule),
      },
      {
        path: 'healthcare-benefit',
        data: {},
        loadChildren: () =>
          import(
            './components/health-care-benefit/health-care-benefit.module'
          ).then((m) => m.HealthCareBenefitModule),
      },
      {
        path: 'hospital-support',
        data: {},
        loadChildren: () =>
          import('./components/hospital-support/hospital-support.module').then(
            (m) => m.HospitalSupportModule
          ),
      },
      {
        path: 'healthcare-calculation',
        data: {},
        loadChildren: () =>
          import(
            './components/health-care-calculate/health-care-calculate.module'
          ).then((m) => m.HealthcarecalculationModule),
      },
      {
        path: 'healthcare-note',
        data: {},
        loadChildren: () =>
          import('./components/health-care-note/health-care-note.module').then(
            (m) => m.HealthCareNoteModule
          ),
      },
      {
        path: 'healthcare-benefit-doc',
        data: {},
        loadChildren: () =>
          import(
            './components/health-benefit-doc/health-benefit-doc.module'
          ).then((m) => m.HealthBenefitDocModule),
      },
      {
        path: 'healthcare-benefit-doc',
        data: {},
        loadChildren: () =>
          import(
            './components/health-benefit-doc/health-benefit-doc.module'
          ).then((m) => m.HealthBenefitDocModule),
      },
      {
        path: 'benefit-table',
        data: {},
        loadChildren: () =>
          import(
            './components/benefit-list-table/benefit-list-table.module'
          ).then((m) => m.BenefitListTableModule),
      },
      {
        path: 'setting',
        data: {},
        loadChildren: () =>
          import('./components/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'report',
        data: {},
        loadChildren: () =>
          import('./components/report/report.module').then(
            (m) => m.ReportModule
          ),
      },
      {
        path: 'about-us',
        data: {},
        loadChildren: () =>
          import('./components/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth/callback',
    component: CallbackComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
