<div class="content">
  <div class="right-side">
    <div class="box-content">
      <div class="header">
        <div class="logo"></div>
        <div class="app-name">
          <h1>{{ "project.nssf" | translate }}</h1>
          <p>{{ "project.sub_nssf" | translate }}</p>
        </div>
        <div class="main-title">
          <p>{{ 'project.title1' | translate }}</p>
          <p>{{ 'project.title2' | translate }}</p>
          <div class="version">
            <h2>{{ 'about_us.version' | translate }} {{ version }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="left-side">
    <div class="box-content">
      <div class="header">
        <ng-container *ngIf="IS_SYSTEM_TESTING; else other">
          <div class="main-title">
            <h1>{{ 'project.title' | translate }}</h1>
            <h2>{{ 'project.system_type' | translate }}</h2>
            <p>{{ 'project.welcome' | translate }}</p>
          </div>
          <h1 class="title">
            {{ 'project.welcome' | translate }}
            <!-- <p>{{ 'project.welcome' | translate }}</p> -->
          </h1>
        </ng-container>
      </div>

      <form *ngIf="!IS_ENABLE_OAUTH" class="mt-0" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="row-login text-left">
          <div class="form-group-login col-md-12 pl px-0">
            <label class="label-control">{{'user.username' | translate}}</label>
            <div class="m-form form-group mb-0">
              <input type="text" class="form-control" [placeholder]="'user.username' | translate"
                formControlName="username" autocomplete="off" required>
              <mat-icon class="icon-prefix m-0 custom-icon" svgIcon="mail"></mat-icon>
            </div>
            <div class="spacer">
              <mat-error *ngIf="loginForm.get('username')?.touched && loginForm.get('username')?.hasError('required')">
                {{ 'login.required_username' | translate}}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row-login text-left">
          <div class="form-group-login col-md-12 pl px-0">
            <label class="label-control">{{'login.password' | translate}}</label>
            <div class="m-form form-group mb-0">
              <input type="text" class="form-control" [placeholder]="'login.password' | translate"
                [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="new-password" required>
              <mat-icon class="icon-prefix m-0 custom-icon" svgIcon="pass"></mat-icon>
              <button type="button" mat-icon-button class="icon-suffix mat-button-reset" (click)="hide = !hide">
                <mat-icon class="m-0 svg-icon">
                  {{hide ? "visibility_off" : "visibility"}}
                </mat-icon>
              </button>
            </div>
            <div class="spacer">
              <mat-error *ngIf="loginForm.get('password')?.touched && !loginForm.get('password')?.valid">
                {{ 'login.required_password' | translate}}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row-login mb-0">
          <div class="col-md-12 px-0">
            <button type="submit" mat-flat-button class="btn-login">
              {{ 'button.login'| translate }}
              <mat-icon class="m-0 svg-icon" svgIcon="next" iconPositionEnd></mat-icon>
            </button>
          </div>
        </div>
      </form>

      <div *ngIf="IS_ENABLE_OAUTH" class="row-login mb-0">
        <div class="col-md-12 px-0">
          <button type="submit" mat-flat-button class="btn-login oauth-btn-login" (click)="onLogin()">
            {{ 'button.login'| translate }}
            <mat-icon class="svg-icon" svgIcon="next" iconPositionEnd></mat-icon>
          </button>
        </div>
      </div>

      <div class="version mt-5">
        <h2>{{ 'about_us.version' | translate }} {{ version }}</h2>
      </div>

      <!-- <div class="mt-4">
				<a [href]="NSSF_AUTH_URL + '/Account/ForgotPassword?client_id=' + NSSF_ADMIN_AUTH_CLIENT_ID">{{ 'login.forgot_pwd' | translate }}</a>
			</div> -->
    </div>
  </div>
  <div class="contenter">
    <div class="header flex-wrap d-flex justify-content-center align-items-center gap">
      <p class="sponsor m-0">{{ 'project.sponsor' | translate}}</p>
      <div class="sponser-group d-flex">
        <div class="first-logo sponser-logo">
          <a target="_blank" href="/assets/images/australian.jpg">
            <img src="/assets/images/australian-h66.webp" class="m-0" loading="lazy">
          </a>
        </div>
        <div class="second-logo sponser-logo">
          <a target="_blank" href="/assets/images/eldz.png">
            <img class="second" src="/assets/images/eldz-h66.webp" class="m-0" loading="lazy">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #other>
  <div class="main-title">
    <h1>{{ 'project.title' | translate }}</h1>
    <p>{{ 'project.welcome' | translate }}</p>
  </div>
  <h1 class="title">{{ 'project.welcome' | translate }}</h1>
</ng-template>
