export const environment = {
  production: false,
  api_url: 'https://nssf-ben-api.myplgs.com/admin/api/v1',
  hos_api_url: 'https://nssf-hos-api.myplgs.com/admin/api/v1',
  // api_url: 'https://nssf-ent-api-stg.myplgs.com/admin/api/v1',

  /** FINGERPRINT LOCAL API */
  local_fingerprint_api_url: 'http://localhost:44301/api/v1',


  /** GENERAL */
  GOOGLE_MAP_API_KEY: 'AIzaSyCVpvjQh5InGMcJ65-N4DFsYJsWBUHnDek',
  DEFAULT_USER_IMAGE: '/assets/images/default_profile.png',
  LIMIT_PRINT_CARD: 20,
  VERSION: '0.0.1',

  /** OAUTH2 */
  NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh',
  NSSF_REDIRECT_URI: 'https://nssf-ben.myplgs.com/auth/callback',
  NSSF_LOGOUT_REDIRECT_URI: 'https://nssf-ben.myplgs.com/login',
  NSSF_ADMIN_AUTH_CLIENT_ID: '7838ecd8-5865-8c28-4093-4e12d65d654c-benefit',
  IS_ENABLE_OAUTH: true,
  IS_SYSTEM_TESTING: false
};
