import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import { RequestService } from 'src/app/services/request.service';
import { User } from 'src/app/models/user';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LANG } from 'src/app/models/enums/lang.enum';
import SmoothScrollbar from 'smooth-scrollbar';
import { HeaderTitle } from 'src/app/models/header-title';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SingleSignOnService } from 'src/app/services/single-sign-on.service';
import { environment } from 'src/environments/environment';
import { EXTENDED_ROUTE_PMS } from 'src/app/models/enums/route-permission';
import { fadeAnimation } from 'src/app/animations/fade-animation';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeAnimation],
})
export class NavigationComponent implements OnInit, OnDestroy {
  isTablet!: boolean;
  isHandset!: boolean;

  NSSF_AUTH_URL: string = environment.NSSF_AUTH_URL;
  IS_ENABLE_OAUTH: boolean = environment.IS_ENABLE_OAUTH;
  NSSF_ADMIN_AUTH_CLIENT_ID: string = environment.NSSF_ADMIN_AUTH_CLIENT_ID;
  IS_SYSTEM_TESTING: boolean = environment.IS_SYSTEM_TESTING;
  version: string = environment.VERSION;

  isContentScrolled!: boolean;
  scrollToTop: boolean = false;

  displayMenuItems: MenuItem[] = [];

  isScrolled!: boolean;
  isMenuIcon: boolean = false;
  menuState: 'open' | 'close' = 'close';
  user!: User;
  lang: string = '';
  permissions: string[] = [];
  title: HeaderTitle | null = null;
  showActionButton: boolean = false;
  readonly LANG = LANG;

  private _destroyed = new Subject<void>();

  constructor(
    private singleSignOnService: SingleSignOnService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    public util: UtilService,
    private authService: AuthService,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private _cd: ChangeDetectorRef,
    private _router: Router
  ) {
    this.lang = this.translate.currentLang as LANG;

    let value: any = this.localStorageService.decryptSpecialCharacter(
      LocalStorageEnum.user
    );
    this.user = JSON.parse(value ? value : '');

    this.util.showActionButton
      .pipe(takeUntil(this._destroyed))
      .subscribe((res) => {
        this.showActionButton = !!res;
      });

    this.permissions = localStorageService.getArray(
      LocalStorageEnum.permissions
    );

    // Auto logout
    if (this.IS_ENABLE_OAUTH && !this.singleSignOnService.isLoggedIn) {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  private _updateActive(root: string) {
    const active = this.displayMenuItems.find((menu) => menu.root === root);
    if (Boolean(active)) {
      if (!active!.expanded) {
        active!.expanded = true;
        this._cd.detectChanges();
      }
    }
  }

  ngOnInit(): void {
    this.getMenuItems();
    this._updateActive(this._router.url.slice(1).split('/').shift()!);
    this._router.events.pipe(takeUntil(this._destroyed)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        const root = ev.url.slice(1).split('/').shift()!;
        this._updateActive(root);
      }
    });

    this.util.headerTitle.subscribe((title) => {
      this.title = title;
    });

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isHandset = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.breakpointObserver
      .observe(Breakpoints.TabletPortrait)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isTablet = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.translate.onLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang as LANG;
      });
  }

  private traverseMenuItems(menuItems: MenuItem[]) {
    return menuItems.filter((item) => {
      if (item.children) {
        item.children = this.traverseMenuItems(item.children);
      }

      // if (!item.roles || !this.user?.role?.code) {
      //   return true;
      // }

      // return item.roles.includes(this.user.role.code);
      return true; //show all menus
    });
  }

  getMenuItems() {
    // Template for checking permission (not available yet)
    // ------
    // isNoPermission: [...EXTENDED_ROUTE_PMS.REQUEST_FORM, ...EXTENDED_ROUTE_PMS.REQUEST_LIST].filter(p => this.permissions?.includes(p)).length === 0,

    const menuItems: MenuItem[] = [
      {
        title: 'menu.dashboard',
        routerLink: '/dashboard',
        icon: 'dashboard',
        isNoPermission:
          EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>
            this.permissions?.includes(p)
          ).length === 0,
        roles: [],
      },
      {
        title: 'menu.injury_Request.injury_Request',
        icon: 'report',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.PRIMARY_WI_REGISTRATION,
            ...EXTENDED_ROUTE_PMS.PRIMARY_WI_VALIDATION,
            ...EXTENDED_ROUTE_PMS.MERGE_SEPARATE_VICTIM,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        root: 'accident-request',
        children: [
          {
            title: 'menu.injury_Request.injury_Report',
            routerLink: '/accident-request/register',
            icon: 'app_registration',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PRIMARY_WI_REGISTRATION.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.injury_Request.injury_list',
            routerLink: '/accident-request/request',
            icon: 'fact_check',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PRIMARY_WI_VALIDATION.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.injury_Request.injury_list_Control',
            routerLink: '/accident-request/injury-report',
            icon: 'sync_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.MERGE_SEPARATE_VICTIM.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'menu.injury_Request.injury_Risk_Report',
          //   routerLink: '/accident-request/injury-report-Control',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.injury_Request.Worker_noreport',
          //   routerLink: '/accident-request/worker-non-report',
          //   icon: 'person',
          //   roles: [],
          // },
        ],
      },
      {
        title: 'menu.inspection.title',
        icon: 'diversity_3',
        root: 'inspection',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.CLAIM_TEMP_REQUEST,
            ...EXTENDED_ROUTE_PMS.CLAIM_REQUEST,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.inspection.inspection_request',
            routerLink: '/inspection/inspection-request',
            icon: 'app_registration',
            isNoPermission:
              EXTENDED_ROUTE_PMS.INSPECTION_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.inspection.inspection_result',
            routerLink: '/inspection/inspection-result',
            icon: 'fact_check',
            isNoPermission:
              EXTENDED_ROUTE_PMS.INSPECTION_RESULT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      // {
      //   title: 'menu.temp_wi_victim.title',
      //   routerLink: '/temp-wi-victim',
      //   icon: 'report',
      //   root: 'temp-wi-victim',
      //   isNoPermission:
      //     EXTENDED_ROUTE_PMS.TEMP_WI_VICTIM.filter((p) =>
      //       this.permissions?.includes(p)
      //     ).length === 0,
      //   roles: [],
      // },
      {
        title: 'menu.retreatment.retreatment_injury',
        routerLink: '/retreatment-injury',
        icon: 'personal_injury',
        root: 'retreatment-injury',
        isNoPermission:
          EXTENDED_ROUTE_PMS.RETREATMENT_REQUEST.filter((p) =>
            this.permissions?.includes(p)
          ).length === 0,
        roles: [],
      },
      {
        title: 'menu.claim_report.receipt',
        icon: 'diversity_3',
        root: 'claim-report',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.CLAIM_TEMP_REQUEST,
            ...EXTENDED_ROUTE_PMS.CLAIM_REQUEST,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.claim_report.temporary',
            routerLink: '/claim-report/temporaryclaimreport',
            icon: 'fact_check',
            isNoPermission:
              EXTENDED_ROUTE_PMS.CLAIM_TEMP_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.claim_report.receive',
            routerLink: '/claim-report/receiveclaimreport',
            icon: 'content_paste_search',
            isNoPermission:
              EXTENDED_ROUTE_PMS.CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.care_ben.doctor_or_calculator_request',
            routerLink: '/claim-report/calculator-doctor',
            icon: 'request_quote',
            isNoPermission:
              EXTENDED_ROUTE_PMS.CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'menu.claim_report.order',
          //   routerLink: '/claim-report/orderclaimreport',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.claim_report.submit',
          //   routerLink: '/claim-report/submitclaimreport',
          //   icon: 'list_alt',
          //   roles: [],
          // },
        ],
      },

      {
        title: 'menu.victim_ben.victim_ben_cal',
        routerLink: '/victim-benifit',
        icon: 'point_of_sale',
        root: 'victim-benifit',
        isNoPermission:
          EXTENDED_ROUTE_PMS.DAILY_INCOME_RECORD.filter((p) =>
            this.permissions?.includes(p)
          ).length === 0,
        roles: [],
      },
      {
        title: 'menu.ben_cal.ben_host',
        icon: 'receipt_long',
        root: 'claim-request-hc',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST_HC,
            ...EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST_HC
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.ben_cal.ben_treatment_doc',
            routerLink: '/claim-request-hc/health-care',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST_HC.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_temporary_doc',
            routerLink: '/claim-request-hc/temporary',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST_HC.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.ben_cal.ben_cal_job',
        icon: 'receipt_long',
        roles: [],
        root: 'claim-request', //benifit-calculate
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST,
            ...EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST,
            ...EXTENDED_ROUTE_PMS.PERMANENT_CLAIM_REQUEST,
            ...EXTENDED_ROUTE_PMS.ALLOWANCE_CLAIM_REQUEST,
            ...EXTENDED_ROUTE_PMS.SURVIVAL_CLAIM_REQUEST,
            ...EXTENDED_ROUTE_PMS.CALCULATE_CLAIM_REQUEST,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.ben_cal.ben_treatment',
            routerLink: '/claim-request/health-care',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_temporary',
            routerLink: '/claim-request/temporary',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_permanent',
            routerLink: '/claim-request/permanent',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PERMANENT_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_cremental',
            routerLink: '/claim-request/allowance',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.ALLOWANCE_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_dead',
            routerLink: '/claim-request/survival',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SURVIVAL_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_cal.ben_cal_job_title',
            routerLink: '/claim-request/calculation',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.CALCULATE_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },

          // {
          //   title: 'menu.ben_cal.ben_treatment',
          //   routerLink: '/benifit-calculate/treatmentbenefit',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_cal.ben_temporary',
          //   routerLink: '/benifit-calculate/temporarybenefit',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_cal.ben_permanent',
          //   routerLink: '/benifit-calculate/permanentbenefit',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_cal.ben_cremental',
          //   routerLink: '/benifit-calculate/cremationbenefit',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_cal.ben_dead',
          //   routerLink: '/benifit-calculate/deadbenefit',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_cal.ben_request',
          //   routerLink: '/benifit-calculate/benefitdemand',
          //   icon: 'list_alt',
          //   roles: [],
          // },
        ],
      },
      {
        title: 'menu.print.title',
        routerLink: '/print',
        icon: 'print_connect',
        root: 'print',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.PRINT_INFO,
            ...EXTENDED_ROUTE_PMS.REQUEST_INFO,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.print.print_info',
            routerLink: '/print/print-info',
            icon: 'print_connect',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PRINT_INFO.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.print.request_info',
            routerLink: '/print/request-info',
            icon: 'app_registration',
            isNoPermission:
              EXTENDED_ROUTE_PMS.REQUEST_INFO.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.ben_con.ben_confirm_req',
        icon: 'receipt_long',
        roles: [],
        root: 'benefit-approval',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_PRINT,
            ...EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_CONFIRM,
            // ...EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_YEARLY_REPORT,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.ben_con.print_ben_con',
            routerLink: '/benefit-approval/benefitprint',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_PRINT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ben_con.ben_con_transfer',
            routerLink: '/benefit-approval/benefitapproval',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_CONFIRM.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'menu.ben_con.branch_ben_order',
          //   routerLink: '/benefit-approval/branchorder',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_con.ben_receive_list',
          //   routerLink: '/benefit-approval/receiver',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_RECEIVER.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'menu.ben_con.ben_yearly_report',
          //   routerLink: '/benefit-approval/yearlyreport',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_YEARLY_REPORT.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
        ],
      },
      // {
      //   title: 'menu.ben_order.ben_order',
      //   routerLink: '/benefit-order',
      //   icon: 'note_alt',
      //   root: 'benefit-order',
      //   roles: [],
      // },
      {
        title: 'menu.non_ben.non_ben_job',
        icon: 'receipt_long',
        roles: [],
        root: 'benefit-non-occupate',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_VICTIM,
            ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_MEDICAL,
            ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_NO_REFUND,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.non_ben.non_ben_victim',
            routerLink: '/benefit-non-occupate/nonoccupatevictims',
            icon: 'post_add',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_VICTIM.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.non_ben.med_ben',
            routerLink: '/benefit-non-occupate/medicalbenefit',
            icon: 'post_add',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_MEDICAL.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.non_ben.ben_non_req',
            routerLink: '/benefit-non-occupate/nonrefund',
            icon: 'post_add',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_NO_REFUND.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.ship_ben.ship_cal',
        icon: 'local_shipping',
        roles: [],
        root: 'shipping-benefit',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_FEE,
            ...EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING,
            ...EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_DECISION,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.ship_ben.ship_ben_info',
            routerLink: '/shipping-benefit/shippingbenefit',
            icon: 'paid',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_FEE.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ship_ben.ship_detail',
            routerLink: '/shipping-benefit/shippinglist',
            icon: 'preview',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.ship_ben.approval',
            routerLink: '/shipping-benefit/shippinggive',
            icon: 'preview',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_DECISION.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      // {
      //   title: 'menu.work_data.work_act_data',
      //   routerLink: '/work-activity',
      //   icon: 'browser_updated',
      //   root: 'work-activity',
      //   isNoPermission:
      //     EXTENDED_ROUTE_PMS.WORK_ACTIVITY_REQUEST.filter((p) =>
      //       this.permissions?.includes(p)
      //     ).length === 0,
      //   roles: [],
      // },
      // {
      //   title: 'menu.ben_scan.ben_maternity',
      //   routerLink: '/maternity-doc',
      //   icon: 'document_scanner',
      //   root: 'maternity-doc',
      //   roles: [],
      // },
      {
        title: 'menu.care_ben.ben_health',
        icon: 'receipt_long',
        roles: [],
        root: 'healthcare-benefit',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVER,
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_TRANSFER_DOC,
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVED,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.care_ben.ben_health_receive',
            routerLink: '/healthcare-benefit/healthreceivebenefit',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVER.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.care_ben.doctor_or_calculator_request',
            routerLink: '/healthcare-benefit/doctor-caculator-request',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVER.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'menu.care_ben.ben_health_transfer',
          //   routerLink: '/healthcare-benefit/healthtransferdoc',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_TRANSFER_DOC.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'menu.care_ben.branch_ben_order',
          //   routerLink: '/healthcare-benefit/healthbranch',
          //   icon: 'list_alt',
          //   roles: [],
          // },
          // {
          //   title: 'menu.care_ben.ben_receive_list',
          //   routerLink: '/healthcare-benefit/receivedbenefit',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVED.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
        ],
      },
      {
        title: 'menu.health_hospital.title',
        icon: 'receipt_long',
        roles: [],
        root: 'hospital-support',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.EMERGENCY_CLAIM_REQUEST_HC,
            ...EXTENDED_ROUTE_PMS.SICK_LEAVE_CLAIM_REQUEST_HC,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            title: 'menu.health_cal.emergency_doc',
            routerLink: '/hospital-support/emergency',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.EMERGENCY_CLAIM_REQUEST_HC.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.health_cal.sick_leave_doc',
            routerLink: '/hospital-support/daily-allowance',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SICK_LEAVE_CLAIM_REQUEST_HC.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.health_cal.health_cal',
        icon: 'receipt_long',
        roles: [],
        root: 'healthcare-calculation',
        isNoPermission:
          [
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVER,
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_TRANSFER_DOC,
            ...EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVED,
          ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          // {
          //   title: 'menu.health_cal.health_care',
          //   routerLink: '/healthcare-calculation/health-care',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          {
            title: 'menu.health_cal.emergency',
            routerLink: '/healthcare-calculation/emergency',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.HC_EMERGENCY_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.health_cal.maternity',
            routerLink: '/healthcare-calculation/maternity',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.HC_MATERNITY_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.health_cal.sick_leave',
            routerLink: '/healthcare-calculation/sick-leave',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.HC_SICK_LEAVE_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'menu.health_cal.transportation',
          //   routerLink: '/healthcare-calculation/transportion',
          //   icon: 'list_alt',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.HC_TRANSPORT_CLAIM_REQUEST.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          {
            title: 'menu.health_cal.funeral',
            routerLink: '/healthcare-calculation/funeral',
            icon: 'list_alt',
            isNoPermission:
              EXTENDED_ROUTE_PMS.HC_FUNERAL_CLAIM_REQUEST.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.health_note.title',
        icon: 'receipt_long',
        root: 'print',
        isNoPermission:
          [...EXTENDED_ROUTE_PMS.NOTE, ...EXTENDED_ROUTE_PMS.APPROVAL].filter(
            (p) => this.permissions?.includes(p)
          ).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.health_note.note',
            routerLink: '/healthcare-note/note',
            icon: 'print_connect',
            isNoPermission:
              EXTENDED_ROUTE_PMS.NOTE.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.health_note.request',
            routerLink: '/healthcare-note/request',
            icon: 'app_registration',
            isNoPermission:
              EXTENDED_ROUTE_PMS.APPROVAL.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },

      // {
      //   title: 'menu.health_doc.health_ben_doc',
      //   routerLink: '/healthcare-benefit-doc',
      //   icon: 'note_alt',
      //   root: 'healthcare-benefit-doc',
      //   roles: [],
      // },

      // {
      //   title: 'menu.ben_list.ben_list_app',
      //   routerLink: '/benefit-table',
      //   icon: 'table_rows_narrow',
      //   root: 'benefit-table',
      //   isNoPermission:
      //     EXTENDED_ROUTE_PMS.BENEFIT_TABLE.filter((p) =>
      //       this.permissions?.includes(p)
      //     ).length === 0,
      //   roles: [],
      // },
      {
        title: 'menu.user_admin',
        routerLink: '/user-admin',
        icon: 'manage_accounts',
        root: 'user-admin',
        isNoPermission:
          EXTENDED_ROUTE_PMS.NSSF_USER.filter((p) =>
            this.permissions?.includes(p)
          ).length === 0,
        roles: [],
      },
      {
        title: 'menu.setting',
        icon: 'settings',
        roles: [],
        isNoPermission:
          EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
            this.permissions?.includes(p)
          ).length === 0,
        root: 'setting',
        expanded: false,
        children: [
          {
            title: 'menu.department',
            routerLink: 'setting/department',
            icon: 'meeting_room',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.role',
            routerLink: 'setting/role',
            icon: 'work',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },

          {
            title: 'setting.disability_type',
            routerLink: 'setting/disablity-type',
            icon: 'accessible',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },

          {
            title: 'setting.relationship',
            routerLink: 'setting/relationship',
            icon: 'supervisor_account',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'setting.hospital.hospital',
          //   routerLink: 'setting/hospital',
          //   icon: 'local_hospital',
          //   isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter(p => this.permissions?.includes(p)).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.medicine.group',
          //   routerLink: 'setting/medicine-group',
          //   icon: 'local_hospital',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.medicine.info',
          //   routerLink: 'setting/medicine-info',
          //   icon: 'healing',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          {
            title: 'setting.accident.group',
            routerLink: 'setting/accident-group',
            icon: 'car_crash',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.account.type',
            routerLink: 'setting/account-type',
            icon: 'switch_account',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.bank',
            routerLink: 'setting/bank',
            icon: 'account_balance',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.benefit.group',
            routerLink: 'setting/benefit-group',
            icon: 'redeem',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.category.visit',
            routerLink: 'setting/category-visit',
            icon: 'emoji_transportation',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.visit_ref_type.title',
            routerLink: 'setting/visit-ref-type',
            icon: 'emoji_transportation',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.type.visit',
            routerLink: 'setting/visit-type',
            icon: 'emoji_transportation',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.emergency.diagnosis_type',
            routerLink: 'setting/diagnosis-type',
            icon: 'medication',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'menu.care_ben.diagnosiss',
            routerLink: 'setting/diagnosis',
            icon: 'medical_services',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },

          {
            title: 'setting.environment.work',
            routerLink: 'setting/environment-work',
            icon: 'stream',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.health.status',
            routerLink: 'setting/health-status',
            icon: 'medication_liquid',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.history_survivors_member_inv_status',
            routerLink: 'setting/history-survivors-member-inv-status',
            icon: 'group',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.hospital_payment.group',
            routerLink: 'setting/hospital-payment-group',
            icon: 'payments',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.injury.group',
            routerLink: 'setting/injury-group',
            icon: 'personal_injury',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.material.group',
            routerLink: 'setting/material-group',
            icon: 'home_repair_service',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            title: 'setting.investigation_survivor_reason',
            routerLink: 'setting/investigation-survivor-reason',
            icon: 'saved_search',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          // {
          //   title: 'setting.hospital.hospital',
          //   routerLink: 'setting/hospital',
          //   icon: 'domain_add',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.hospital_type',
          //   routerLink: 'setting/hospital-type',
          //   icon: 'local_hospital',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.medical_hospital_reason',
          //   routerLink: 'setting/medical-hospital-reason',
          //   icon: 'local_hospital',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.hospital_level',
          //   routerLink: 'setting/hospital-level',
          //   icon: 'local_hospital',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          // {
          //   title: 'setting.hospital_package_level',
          //   routerLink: 'setting/hospital-package-level',
          //   icon: 'medical_services',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          //   roles: [],
          // },
          {
            title: 'setting.claim_case',
            routerLink: 'setting/hc-claim-case',
            icon: 'content_paste_search',
            isNoPermission:
              EXTENDED_ROUTE_PMS.SETTING.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        title: 'menu.work_injury_report',
        icon: 'report',
        root: 'report',
        isNoPermission: [
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIM_REQ_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIM_AMOUNT_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIMANT_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIM_BY_ENT_ADDR_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIM_APPROVAL_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_WI_CLAIM_EXPANSE_REPORT,
        ].filter(p => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          // {
          //   title: 'menu.occupational_risk_expense',
          //   routerLink: '/report/wi/claim-wi',
          //   icon: 'corporate_fare',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.READ_WI_CLAIM_REQ_REPORT.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          // },
          {
            title: 'menu.provision_occupational_risk_assessment',
            routerLink: '/report/wi/officer-wi',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIM_AMOUNT_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          {
            title: 'menu.list_beneficiaries',
            routerLink: '/report/wi/list-wi-member',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIMANT_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },

          {
            title: 'menu.claim_wi',
            routerLink: '/report/wi/claim-wi-report',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIM_BY_ENT_ADDR_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          {
            title: 'menu.provision_claim_wi',
            routerLink: '/report/wi/provision_claim-wi-report',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIM_APPROVAL_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          {
            title: 'menu.expend_claim_wi',
            routerLink: '/report/wi/expend_claim-wi-report',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIM_EXPANSE_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
        ],
      },
      {
        title: 'menu.hc_report',
        icon: 'report',
        root: 'report',
        isNoPermission: [
          ...EXTENDED_ROUTE_PMS.READ_HC_CLAIM_REQ_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_HC_CLAIM_AMOUNT_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_HC_CLAIM_BY_ENT_ADDR_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_HC_CLAIM_APPROVAL_REPORT,
          ...EXTENDED_ROUTE_PMS.READ_HC_CLAIM_EXPANSE_REPORT,
        ].filter(p => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'menu.health_care_claim',
            routerLink: '/report/hc/hc-claim',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_HC_CLAIM_REQ_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          // {
          //   title: 'menu.summary_request',
          //   routerLink: '/report/hc/summary',
          //   icon: 'corporate_fare',
          //   isNoPermission:
          //     EXTENDED_ROUTE_PMS.READ_HC_CLAIM_AMOUNT_REPORT.filter((p) =>
          //       this.permissions?.includes(p)
          //     ).length === 0,
          // },
          {
            title: 'menu.list_beneficiaries',
            routerLink: '/report/hc/list-hc-member',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_WI_CLAIMANT_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },

          {
            title: 'menu.report_request_provinces',
            routerLink: '/report/hc/claim',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_HC_CLAIM_BY_ENT_ADDR_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          {
            title: 'menu.report_payment_hc',
            routerLink: '/report/hc/payment',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_HC_CLAIM_APPROVAL_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
          {
            title: 'menu.health_care_claim',
            routerLink: '/report/hc/provincial-city-hc-claim',
            icon: 'corporate_fare',
            isNoPermission:
              EXTENDED_ROUTE_PMS.READ_HC_CLAIM_EXPANSE_REPORT.filter((p) =>
                this.permissions?.includes(p)
              ).length === 0,
          },
        ],
      },
    ];
    this.displayMenuItems = this.traverseMenuItems(menuItems);
  }

  onToggleMenu(): void {
    this.isMenuIcon = !this.isMenuIcon;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  onCloseMenu(): void {
    this.isMenuIcon = true;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  toggleExpendMenu(menu: MenuItem): void {
    menu.expanded = !menu.expanded;
  }

  onMenuScroll(e: ScrollStatus): void {
    let scrolled = e.offset.y > 0;
    if (this.isScrolled != scrolled) {
      this.isScrolled = scrolled;
      this._cd.detectChanges();
    }
  }

  onClickMenu() {
    if (!this.isMenuIcon) {
      this.isMenuIcon = this.isHandset || this.isTablet;
    }
  }

  onLogout() {
    if (this.IS_ENABLE_OAUTH && this.singleSignOnService.isLoggedIn) {
      this.singleSignOnService.onLogout();
    } else {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  onScroll(status: ScrollStatus): void {
    let scrolled = status.offset.y > 200;
    if (this.isContentScrolled != scrolled) {
      this.isContentScrolled = scrolled;
      this._cd.detectChanges();
    }

    this.util.navigationScroll.emit(status);
  }

  onScrollLoaded(scroll: SmoothScrollbar): void {
    this.util.navigationScrollRef = scroll;
  }

  moveTop() {
    this.util.navigationScrollRef.scrollTo(0, 0, 350);
    this.util.navigationScrollRef.setMomentum(0, 1);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}

export interface MenuItem {
  title: string; // Title to be displayed
  exact?: boolean; // Highlight when active or not
  routerLink?: string; // Link to redirect when click
  children?: MenuItem[]; // Children of the menu
  roles?: string[]; // Allowed roles for the menu
  icon?: string; // Icon to be displayed
  root?: string;
  expanded?: boolean; // Show children or not
  isNoPermission?: boolean;
}
