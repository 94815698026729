import { PERMISSION, SECTION } from './permission.enum';

export const ROUTE_PERMISSIONS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [SECTION.DASHBOARD, PERMISSION.READ_DASHBOARD],
  // -----------------------------------------------------
  // MAIN: inspection requesy
  // -----------------------------------------------------
  INSPECTION_REQUEST: [
    SECTION.INSPECTION_REQUEST,
    PERMISSION.READ_INSPECT_REQ,
    PERMISSION.ALLOW_CREATE_INSPECT_REQ,
    PERMISSION.ALLOW_EDIT_INSPECT_REC,
    PERMISSION.ALLOW_DELETE_INSPECT_REQ,
  ],
  INSPECTION_RESULT: [
    SECTION.INSPECTION_RESULT,
    PERMISSION.READ_INSPECT_RESULT,
  ],

  // -----------------------------------------------------
  // MAIN: PRIMARY INFO
  // -----------------------------------------------------
  PRIMARY_WI_REQUEST: [
    SECTION.PRIMARY_WI_REGISTRATION,
    PERMISSION.ALLOW_CREATE_WI_REQ,
    SECTION.PRIMARY_WI_VALIDATION,
    PERMISSION.READ_WI_REQ,
    PERMISSION.ALLOW_ACCEPT_WI_REQ,
    PERMISSION.ALLOW_RETURN_WI_REQ,
    PERMISSION.ALLOW_REJECT_WI_REQ,
    PERMISSION.ALLOW_EDIT_WI_REQ,
    PERMISSION.ALLOW_ALLOCATE_WI_REQ,
    SECTION.MERGE_SEPARATE_VICTIM,
    PERMISSION.ALLOW_MERGE_SEPARATE_WI_VICTIM,
  ],

  // -----------------------------------------------------
  // MAIN: PRIMARY INFO
  // -----------------------------------------------------
  TEMP_WI_VICTIM: [
    SECTION.TEMP_WI_VICTIM,
    PERMISSION.READ_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_CREATE_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_EDIT_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_TRANSFER_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_COMPLETE_TEMP_WI_VICTIM,
  ],

  // -----------------------------------------------------
  // MAIN: RETREATMENT REQUEST
  // -----------------------------------------------------
  RETREATMENT_REQUEST: [
    SECTION.RETREATMENT_REQUEST,
    PERMISSION.ALLOW_CREATE_RETREAT_REQ,
    PERMISSION.READ_RETREAT_REQ,
    PERMISSION.ALLOW_ACCEPT_RETREAT_REQ,
    PERMISSION.ALLOW_RETURN_RETREAT_REQ,
    PERMISSION.ALLOW_REJECT_RETREAT_REQ,
    PERMISSION.ALLOW_EDIT_RETREAT_REQ,
    PERMISSION.ALLOW_ALLOCATE_RETREAT_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: CLAIM REPORT
  // -----------------------------------------------------
  CLAIM_REQUEST: [
    SECTION.CLAIM_TEMP_REQUEST,
    PERMISSION.READ_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_CREATE_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_ACCEPT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_RETURN_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_REJECT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_EDIT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_ALLOCATE_CLAIM_TEMP_REQ,

    SECTION.CLAIM_REQUEST,
    PERMISSION.READ_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_CLAIM_REQ,
    PERMISSION.ALLOW_TRANSFER_TO_DOCTOR,
    PERMISSION.ALLOW_TRANSFER_TO_CALCULATOR,
  ],
  // -----------------------------------------------------
  // MAIN: DAILY INCOME RECORD
  // -----------------------------------------------------
  DAILY_INCOME_RECORD: [
    SECTION.DAILY_INCOME_RECORD,
    PERMISSION.READ_DAILY_INCOME_REC,
    PERMISSION.ALLOW_CREATE_DAILY_INCOME_REC,
  ],
  // -----------------------------------------------------
  // MAIN: Benefit Claim Request
  // -----------------------------------------------------
  BENEFIT_CLAIM_REQUEST_HC: [
    SECTION.MEDICAL_CLAIM_REQUEST,
    PERMISSION.READ_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MEDICAL_DOC_CLAIM_REQ,

    SECTION.TEMPORARY_CLAIM_REQUEST,
    PERMISSION.READ_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_TEMP_DOC_CLAIM_REQ,
  ],

  BENEFIT_CLAIM_REQUEST: [
    SECTION.MEDICAL_CLAIM_REQUEST,
    PERMISSION.READ_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MEDICAL_CLAIM_REQ,

    SECTION.TEMPORARY_CLAIM_REQUEST,
    PERMISSION.READ_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_TEMP_CLAIM_REQ,

    SECTION.PERMANENT_CLAIM_REQUEST,
    PERMISSION.READ_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_PERM_CLAIM_REQ,

    SECTION.ALLOWANCE_CLAIM_REQUEST,
    PERMISSION.READ_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_ALLOW_CLAIM_REQ,

    SECTION.SURVIVAL_CLAIM_REQUEST,
    PERMISSION.READ_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SURVIVAL_CLAIM_REQ,

    SECTION.CALCULATE_CLAIM_REQUEST,
    PERMISSION.READ_CALCULATE_REQ,
    PERMISSION.ALLOW_CREATE_CALCULATE_REQ,
    PERMISSION.ALLOW_ACCEPT_CALCULATE_REQ,
    PERMISSION.ALLOW_RETURN_CALCULATE_REQ,
    PERMISSION.ALLOW_REJECT_CALCULATE_REQ,
    PERMISSION.ALLOW_EDIT_CALCULATE_REQ,
    PERMISSION.ALLOW_ALLOCATE_CALCULATE_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: PRINT INFO
  // -----------------------------------------------------
  PRINT_INFO: [
    SECTION.PRINT_INFO,
    PERMISSION.READ_PRINT_INFO_REQ,
    PERMISSION.ALLOW_CREATE_PRINT_INFO_REQ,
    PERMISSION.ALLOW_ACCEPT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_RETURN_PRINT_INFO_REQ,
    PERMISSION.ALLOW_REJECT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_EDIT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_ALLOCATE_PRINT_INFO_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: REQUEST INFO
  // -----------------------------------------------------

  REQUEST_INFO: [
    SECTION.REQUEST_INFO,
    PERMISSION.READ_BNV_REQ,
    PERMISSION.ALLOW_CREATE_BNV_REQ,
    PERMISSION.ALLOW_EDIT_BNV_REQ,
    PERMISSION.ALLOW_ACCEPT_BNV_REQ,
    PERMISSION.ALLOW_RETURN_BNV_REQ,
    PERMISSION.ALLOW_REJECT_BNV_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNV_REQ,
    // PERMISSION.ALLOW_APPROVE_BNV_REQ,
    // PERMISSION.READ_BNV_ASSIGN,

  ],

  // -----------------------------------------------------
  // MAIN: Benefit Approval Print
  // -----------------------------------------------------
  BENEFIT_APPROVAL: [
    SECTION.BENEFIT_APPROVAL_PRINT,
    PERMISSION.READ_BENEFIT_CONFIRM_REQ,
    PERMISSION.READ_BNC_REQ,

    PERMISSION.ALLOW_CREATE_BNC_REQ,
    PERMISSION.ALLOW_EDIT_BNC_REQ,
    PERMISSION.ALLOW_ACCEPT_BNC_REQ,
    PERMISSION.ALLOW_RETURN_BNC_REQ,
    PERMISSION.ALLOW_REJECT_BNC_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNC_REQ,
    // PERMISSION.ALLOW_APPROVE_BNC_REQ,
    // PERMISSION.READ_BNC_ASSIGN,


    PERMISSION.ALLOW_APPROVE_BENEFIT_APPROVAL_REQ,
    PERMISSION.READ_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_CREATE_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_ACCEPT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_RETURN_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_REJECT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_EDIT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_ALLOCATE_BENEFIT_CONFIRM_RECEIVER_REQ,

    SECTION.BENEFIT_APPROVAL_YEARLY_REPORT,
    PERMISSION.READ_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_CREATE_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_ACCEPT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_RETURN_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_REJECT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_EDIT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_ALLOCATE_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Benefit Non Occupate
  // -----------------------------------------------------
  BENEFIT_NON_OCCUPATION: [
    SECTION.BENEFIT_NON_OCCUPATION_VICTIM,
    PERMISSION.READ_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_CREATE_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_RETURN_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_REJECT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_EDIT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_VICTIM_REQ,

    SECTION.BENEFIT_NON_OCCUPATION_MEDICAL,
    PERMISSION.READ_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_CREATE_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_RETURN_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_REJECT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_EDIT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_MEDICAL_REQ,

    SECTION.BENEFIT_NON_OCCUPATION_NO_REFUND,
    PERMISSION.READ_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_CREATE_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_RETURN_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_REJECT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_EDIT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_NO_REFUND_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Shipping Benefit
  // -----------------------------------------------------
  BENEFIT_SHIPPING: [
    SECTION.BENEFIT_SHIPPING_FEE,
    PERMISSION.READ_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_FEE_REQ,

    SECTION.BENEFIT_SHIPPING,
    PERMISSION.READ_SHIPPING_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_REQ,

    SECTION.BENEFIT_SHIPPING_DECISION,
    PERMISSION.READ_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_DECISION_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Work Activity
  // -----------------------------------------------------
  WORK_ACTIVITY_REQUEST: [
    SECTION.WORK_ACTIVITY_REQUEST,
    PERMISSION.READ_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_CREATE_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_ACCEPT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_RETURN_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_REJECT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_EDIT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_ALLOCATE_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_DOWNLOAD_WORK_ACTIVITY_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Health Care Benefit
  // -----------------------------------------------------
  BENEFIT_HEALTH_CARE: [
    SECTION.BENEFIT_HEALTH_CARE_RECEIVER,
    PERMISSION.READ_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_RECEIVER_REQ,

    SECTION.BENEFIT_HEALTH_CARE_TRANSFER_DOC,
    PERMISSION.READ_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_TRANSFER_REQ,

    SECTION.BENEFIT_HEALTH_CARE_RECEIVED,
    PERMISSION.READ_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_RECEIVED_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: HC
  // -----------------------------------------------------
  CLAIM_REQUEST_HC_DOC: [
    PERMISSION.READ_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_EMERGENCY_DOC_CLAIM_REQ,

    PERMISSION.READ_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SICK_LEAVE_DOC_CLAIM_REQ,
  ],

  // SUB : HC Emergency Claim Request
  HC_CLAIM_REQUEST: [
    PERMISSION.READ_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_EMERGENCY_CLAIM_REQ,

    PERMISSION.READ_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MATERNITY_CLAIM_REQ,


    PERMISSION.READ_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SICK_LEAVE_CLAIM_REQ,

    PERMISSION.READ_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_FUNERAL_CLAIM_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Benefit Table
  // -----------------------------------------------------
  BENEFIT_TABLE: [
    SECTION.BENEFIT_TABLE,
    PERMISSION.READ_BEN_TABLE_REQ,
    PERMISSION.ALLOW_CREATE_BEN_TABLE_REQ,
    PERMISSION.ALLOW_ACCEPT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_RETURN_BEN_TABLE_REQ,
    PERMISSION.ALLOW_REJECT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_EDIT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_ALLOCATE_BEN_TABLE_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: Health Care Benefit Note
  // -----------------------------------------------------
  HC_BENEFIT_NOTE: [
    PERMISSION.READ_HC_BEN_NOTE,
    PERMISSION.ALLOW_PRINT_HC_BEN_NOTE,
    PERMISSION.ALLOW_CREATE_HC_BEN_NOTE,
    PERMISSION.ALLOW_ACCEPT_HC_BEN_NOTE,
    PERMISSION.ALLOW_REJECT_HC_BEN_NOTE,

    PERMISSION.READ_HC_BEN_APP,
    PERMISSION.ALLOW_CREATE_HC_BEN_APP,
    PERMISSION.ALLOW_APPROVE_HC_BEN_APP,
    PERMISSION.ALLOW_REJECT_HC_BEN_APP,
    PERMISSION.ALLOW_PRINT_HC_BEN_APP
  ],

  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  // SYSTEM_TRACKING: [
  //   SECTION.SYSTEM_TRACKING,
  //   PERMISSION.READ_TRACKING
  // ],
  // -----------------------------------------------------
  // MAIN: USERS
  // -----------------------------------------------------
  NSSF_USER: [
    SECTION.NSSF_USER,
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],
  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    SECTION.SETTING,
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
  // -----------------------------------------------------
  // MAIN: REPORT
  // -----------------------------------------------------
  ALL_REPORT: [
    PERMISSION.READ_WI_CLAIM_REQ_REPORT,
    PERMISSION.READ_WI_CLAIM_AMOUNT_REPORT,
    PERMISSION.READ_HC_CLAIM_REQ_REPORT,
    PERMISSION.READ_HC_CLAIM_REQ_REPORT,
  ],
  REPORT_CLIAM_WI: [
    PERMISSION.READ_WI_CLAIM_REQ_REPORT,
  ],
  REPORT_OFFICER_WI: [
    PERMISSION.READ_WI_CLAIM_AMOUNT_REPORT,

  ],
  REPORT_CLIAM_HC: [
    PERMISSION.READ_HC_CLAIM_REQ_REPORT,
  ],
  REPORT_OFFICER_HC: [
    PERMISSION.READ_HC_CLAIM_REQ_REPORT,
  ],
};

export const EXTENDED_ROUTE_PMS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],
  // -----------------------------------------------------
  // MAIN: inspection requesy
  // -----------------------------------------------------
  INSPECTION_REQUEST: [
    SECTION.INSPECTION_REQUEST,
    PERMISSION.READ_INSPECT_REQ,
    PERMISSION.ALLOW_CREATE_INSPECT_REQ,
    PERMISSION.ALLOW_EDIT_INSPECT_REC,
    PERMISSION.ALLOW_DELETE_INSPECT_REQ,
  ],
  INSPECTION_RESULT: [
    SECTION.INSPECTION_RESULT,
    PERMISSION.READ_INSPECT_RESULT,
  ],
  // -----------------------------------------------------
  // MAIN: PRIMARY INFO
  // -----------------------------------------------------
  PRIMARY_WI_REGISTRATION: [PERMISSION.ALLOW_CREATE_WI_REQ],
  PRIMARY_WI_VALIDATION: [
    PERMISSION.READ_WI_REQ,
    PERMISSION.ALLOW_ACCEPT_WI_REQ,
    PERMISSION.ALLOW_RETURN_WI_REQ,
    PERMISSION.ALLOW_REJECT_WI_REQ,
    PERMISSION.ALLOW_EDIT_WI_REQ,
    PERMISSION.ALLOW_ALLOCATE_WI_REQ,
  ],
  MERGE_SEPARATE_VICTIM: [PERMISSION.ALLOW_MERGE_SEPARATE_WI_VICTIM],

  // -----------------------------------------------------
  //  TEMP_WI_VICTIM
  // -----------------------------------------------------
  TEMP_WI_VICTIM: [
    PERMISSION.READ_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_CREATE_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_EDIT_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_TRANSFER_TEMP_WI_VICTIM,
    PERMISSION.ALLOW_COMPLETE_TEMP_WI_VICTIM,
  ],

  // -----------------------------------------------------
  // MAIN: RETREATMENT
  // -----------------------------------------------------
  RETREATMENT_REQUEST: [
    PERMISSION.READ_RETREAT_REQ,
    PERMISSION.ALLOW_CREATE_RETREAT_REQ,
    PERMISSION.ALLOW_ACCEPT_RETREAT_REQ,
    PERMISSION.ALLOW_RETURN_RETREAT_REQ,
    PERMISSION.ALLOW_REJECT_RETREAT_REQ,
    PERMISSION.ALLOW_EDIT_RETREAT_REQ,
    PERMISSION.ALLOW_ALLOCATE_RETREAT_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: CLAIM REPORT
  // -----------------------------------------------------
  CLAIM_TEMP_REQUEST: [
    PERMISSION.READ_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_CREATE_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_ACCEPT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_RETURN_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_REJECT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_EDIT_CLAIM_TEMP_REQ,
    PERMISSION.ALLOW_ALLOCATE_CLAIM_TEMP_REQ,
  ],
  CLAIM_REQUEST: [
    PERMISSION.READ_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_CLAIM_REQ,
    PERMISSION.ALLOW_TRANSFER_TO_DOCTOR,
    PERMISSION.ALLOW_TRANSFER_TO_CALCULATOR,
  ],
  // -----------------------------------------------------
  // MAIN: DAILY INCOME RECORD
  // -----------------------------------------------------
  DAILY_INCOME_RECORD: [
    PERMISSION.READ_DAILY_INCOME_REC,
    PERMISSION.ALLOW_CREATE_DAILY_INCOME_REC,
  ],
  // -----------------------------------------------------
  // MAIN: CLAIM REPORT
  // -----------------------------------------------------
  MEDICAL_CLAIM_REQUEST_HC: [
    PERMISSION.READ_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MEDICAL_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MEDICAL_DOC_CLAIM_REQ,
  ],
  TEMPORARY_CLAIM_REQUEST_HC: [
    PERMISSION.READ_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_TEMP_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_TEMP_DOC_CLAIM_REQ,
  ],

  MEDICAL_CLAIM_REQUEST: [
    PERMISSION.READ_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MEDICAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MEDICAL_CLAIM_REQ,
  ],
  TEMPORARY_CLAIM_REQUEST: [
    PERMISSION.READ_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_TEMP_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_TEMP_CLAIM_REQ,
  ],
  PERMANENT_CLAIM_REQUEST: [
    PERMISSION.READ_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_PERM_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_PERM_CLAIM_REQ,
  ],
  ALLOWANCE_CLAIM_REQUEST: [
    PERMISSION.READ_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_ALLOW_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_ALLOW_CLAIM_REQ,
  ],
  SURVIVAL_CLAIM_REQUEST: [
    PERMISSION.READ_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SURVIVAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SURVIVAL_CLAIM_REQ,
  ],
  CALCULATE_CLAIM_REQUEST: [
    PERMISSION.READ_CALCULATE_REQ,
    PERMISSION.ALLOW_CREATE_CALCULATE_REQ,
    PERMISSION.ALLOW_ACCEPT_CALCULATE_REQ,
    PERMISSION.ALLOW_RETURN_CALCULATE_REQ,
    PERMISSION.ALLOW_REJECT_CALCULATE_REQ,
    PERMISSION.ALLOW_EDIT_CALCULATE_REQ,
    PERMISSION.ALLOW_ALLOCATE_CALCULATE_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: PRINT INFO
  // -----------------------------------------------------
  PRINT_INFO: [
    PERMISSION.READ_PRINT_INFO_REQ,
    PERMISSION.ALLOW_CREATE_PRINT_INFO_REQ,
    PERMISSION.ALLOW_ACCEPT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_RETURN_PRINT_INFO_REQ,
    PERMISSION.ALLOW_REJECT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_EDIT_PRINT_INFO_REQ,
    PERMISSION.ALLOW_ALLOCATE_PRINT_INFO_REQ,
  ],

  REQUEST_INFO: [
    PERMISSION.READ_BNV_REQ,
    PERMISSION.ALLOW_CREATE_BNV_REQ,
    PERMISSION.ALLOW_EDIT_BNV_REQ,
    PERMISSION.ALLOW_ACCEPT_BNV_REQ,
    PERMISSION.ALLOW_RETURN_BNV_REQ,
    PERMISSION.ALLOW_REJECT_BNV_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNV_REQ,
    // PERMISSION.ALLOW_APPROVE_BNV_REQ,
    // PERMISSION.READ_BNV_ASSIGN,
  ],

  // -----------------------------------------------------
  // MAIN: Benefit Approval Print
  // -----------------------------------------------------
  BENEFIT_APPROVAL_PRINT: [
    PERMISSION.READ_BENEFIT_CONFIRM_REQ,
    PERMISSION.ALLOW_APPROVE_BENEFIT_APPROVAL_REQ,
  ],

  BENEFIT_APPROVAL_CONFIRM: [
    PERMISSION.READ_BNC_REQ,
    PERMISSION.ALLOW_CREATE_BNC_REQ,
    PERMISSION.ALLOW_EDIT_BNC_REQ,
    PERMISSION.ALLOW_ACCEPT_BNC_REQ,
    PERMISSION.ALLOW_RETURN_BNC_REQ,
    PERMISSION.ALLOW_REJECT_BNC_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNC_REQ,
    // PERMISSION.ALLOW_APPROVE_BNC_REQ,
    // PERMISSION.READ_BNC_ASSIGN,
  ],
  BENEFIT_APPROVAL_RECEIVER: [
    PERMISSION.READ_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_CREATE_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_ACCEPT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_RETURN_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_REJECT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_EDIT_BENEFIT_CONFIRM_RECEIVER_REQ,
    PERMISSION.ALLOW_ALLOCATE_BENEFIT_CONFIRM_RECEIVER_REQ,
  ],
  BENEFIT_APPROVAL_YEARLY_REPORT: [
    PERMISSION.READ_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_CREATE_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_ACCEPT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_RETURN_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_REJECT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_EDIT_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
    PERMISSION.ALLOW_ALLOCATE_BENEFIT_CONFIRM_YEARLY_REPORT_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Benefit Non Occupate
  // -----------------------------------------------------
  BENEFIT_NON_OCCUPATION_VICTIM: [
    PERMISSION.READ_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_CREATE_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_RETURN_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_REJECT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_EDIT_BNO_VICTIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_VICTIM_REQ,
  ],
  BENEFIT_NON_OCCUPATION_MEDICAL: [
    PERMISSION.READ_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_CREATE_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_RETURN_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_REJECT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_EDIT_BNO_MEDICAL_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_MEDICAL_REQ,
  ],
  BENEFIT_NON_OCCUPATION_NO_REFUND: [
    PERMISSION.READ_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_CREATE_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_ACCEPT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_RETURN_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_REJECT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_EDIT_BNO_NO_REFUND_REQ,
    PERMISSION.ALLOW_ALLOCATE_BNO_NO_REFUND_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Shipping Benefit
  // -----------------------------------------------------
  BENEFIT_SHIPPING_FEE: [
    PERMISSION.READ_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_FEE_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_FEE_REQ,
  ],
  BENEFIT_SHIPPING: [
    PERMISSION.READ_SHIPPING_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_REQ,
  ],
  BENEFIT_SHIPPING_DECISION: [
    PERMISSION.READ_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_CREATE_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_ACCEPT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_RETURN_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_REJECT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_EDIT_SHIPPING_DECISION_REQ,
    PERMISSION.ALLOW_ALLOCATE_SHIPPING_DECISION_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Work Activity
  // -----------------------------------------------------
  WORK_ACTIVITY_REQUEST: [
    PERMISSION.READ_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_CREATE_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_ACCEPT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_RETURN_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_REJECT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_EDIT_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_ALLOCATE_WORK_ACTIVITY_REQ,
    PERMISSION.ALLOW_DOWNLOAD_WORK_ACTIVITY_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Health Care Benefit
  // -----------------------------------------------------
  BENEFIT_HEALTH_CARE_RECEIVER: [
    PERMISSION.READ_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_RECEIVER_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_RECEIVER_REQ,
  ],
  BENEFIT_HEALTH_CARE_TRANSFER_DOC: [
    PERMISSION.READ_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_TRANSFER_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_TRANSFER_REQ,
  ],
  BENEFIT_HEALTH_CARE_RECEIVED: [
    PERMISSION.READ_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_CREATE_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_ACCEPT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_RETURN_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_REJECT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_EDIT_HEALTH_CARE_RECEIVED_REQ,
    PERMISSION.ALLOW_ALLOCATE_HEALTH_CARE_RECEIVED_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: HC
  // -----------------------------------------------------
  // SUB : HC Emergency Claim Request
  EMERGENCY_CLAIM_REQUEST_HC: [
    PERMISSION.READ_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_EMERGENCY_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_EMERGENCY_DOC_CLAIM_REQ,

  ],
  // SUB : HC Emergency Claim Request
  HC_EMERGENCY_CLAIM_REQUEST: [
    PERMISSION.READ_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_EMERGENCY_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_EMERGENCY_CLAIM_REQ,
  ],
  // SUB : HC Maternity Claim Request
  HC_MATERNITY_CLAIM_REQUEST: [
    PERMISSION.READ_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_MATERNITY_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_MATERNITY_CLAIM_REQ,

  ],
  // SUB : HC Sick Leave Claim Request
  SICK_LEAVE_CLAIM_REQUEST_HC: [
    PERMISSION.READ_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SICK_LEAVE_DOC_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SICK_LEAVE_DOC_CLAIM_REQ,

  ],
  // SUB : HC Sick Leave Claim Request
  HC_SICK_LEAVE_CLAIM_REQUEST: [
    PERMISSION.READ_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_SICK_LEAVE_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_SICK_LEAVE_CLAIM_REQ,

  ],
  // SUB : HC Transport Claim Request
  HC_TRANSPORT_CLAIM_REQUEST: [
    PERMISSION.READ_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_TRANSPORT_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_TRANSPORT_CLAIM_REQ,
  ],
  // SUB : HC Funeral Claim Request
  HC_FUNERAL_CLAIM_REQUEST: [
    PERMISSION.READ_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_CREATE_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_ACCEPT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_RETURN_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_REJECT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_EDIT_FUNERAL_CLAIM_REQ,
    PERMISSION.ALLOW_ALLOCATE_FUNERAL_CLAIM_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: Benefit Table
  // -----------------------------------------------------
  BENEFIT_TABLE: [
    PERMISSION.READ_BEN_TABLE_REQ,
    PERMISSION.ALLOW_CREATE_BEN_TABLE_REQ,
    PERMISSION.ALLOW_ACCEPT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_RETURN_BEN_TABLE_REQ,
    PERMISSION.ALLOW_REJECT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_EDIT_BEN_TABLE_REQ,
    PERMISSION.ALLOW_ALLOCATE_BEN_TABLE_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: Benefit Note
  // -----------------------------------------------------
  // SUB : HC Benefit Note
  NOTE: [
    PERMISSION.READ_HC_BEN_NOTE,
    PERMISSION.ALLOW_CREATE_HC_BEN_NOTE,
    PERMISSION.ALLOW_ACCEPT_HC_BEN_NOTE,
    PERMISSION.ALLOW_REJECT_HC_BEN_NOTE,
    PERMISSION.ALLOW_PRINT_HC_BEN_NOTE,
  ],
  // SUB : HC Benefit Approval
  APPROVAL: [
    PERMISSION.READ_HC_BEN_APP,
    PERMISSION.ALLOW_CREATE_HC_BEN_APP,
    PERMISSION.ALLOW_APPROVE_HC_BEN_APP,
    PERMISSION.ALLOW_REJECT_HC_BEN_APP,
    PERMISSION.ALLOW_PRINT_HC_BEN_APP
  ],

  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  // SYSTEM_TRACKING: [
  //   PERMISSION.READ_TRACKING
  // ],
  // -----------------------------------------------------
  // MAIN: USERS
  // -----------------------------------------------------
  NSSF_USER: [
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],

  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
  // -----------------------------------------------------
  // MAIN: REPORT
  // -----------------------------------------------------
  // ALL_REPORT: [
  //   PERMISSION.READ_WI_CLAIM_REQ_REPORT,
  //   PERMISSION.READ_WI_CLAIM_AMOUNT_REPORT,
  //   PERMISSION.READ_HC_CLAIM_REQ_REPORT,
  //   PERMISSION.READ_HC_CLAIM_REQ_REPORT,

  //   PERMISSION.READ_WI_CLAIMANT_REPORT,
  //   PERMISSION.READ_WI_CLAIM_BY_ENT_ADDR_REPORT,
  //   PERMISSION.READ_WI_CLAIM_APPROVAL_REPORT,
  //   PERMISSION.READ_WI_CLAIM_EXPANSE_REPORT,

  //   PERMISSION.READ_HC_CLAIM_REQ_REPORT,
  //   PERMISSION.READ_HC_CLAIM_AMOUNT_REPORT,
  //   PERMISSION.READ_HC_CLAIM_BY_ENT_ADDR_REPORT,
  //   PERMISSION.READ_HC_CLAIM_APPROVAL_REPORT,
  //   PERMISSION.READ_HC_CLAIM_EXPANSE_REPORT,
  // ]

  READ_WI_CLAIM_REQ_REPORT: [
    PERMISSION.READ_WI_CLAIM_REQ_REPORT
  ],
  READ_WI_CLAIM_AMOUNT_REPORT: [
    PERMISSION.READ_WI_CLAIM_AMOUNT_REPORT
  ],
  READ_WI_CLAIMANT_REPORT: [
    PERMISSION.READ_WI_CLAIMANT_REPORT
  ],
  READ_WI_CLAIM_BY_ENT_ADDR_REPORT: [
    PERMISSION.READ_WI_CLAIM_BY_ENT_ADDR_REPORT
  ],
  READ_WI_CLAIM_APPROVAL_REPORT: [
    PERMISSION.READ_WI_CLAIM_APPROVAL_REPORT
  ],
  READ_WI_CLAIM_EXPANSE_REPORT: [
    PERMISSION.READ_WI_CLAIM_EXPANSE_REPORT
  ],


  READ_HC_CLAIM_REQ_REPORT: [
    PERMISSION.READ_HC_CLAIM_REQ_REPORT
  ],
  READ_HC_CLAIM_AMOUNT_REPORT: [
    PERMISSION.READ_HC_CLAIM_AMOUNT_REPORT
  ],
  READ_HC_CLAIM_BY_ENT_ADDR_REPORT: [
    PERMISSION.READ_HC_CLAIM_BY_ENT_ADDR_REPORT
  ],
  READ_HC_CLAIM_APPROVAL_REPORT: [
    PERMISSION.READ_HC_CLAIM_APPROVAL_REPORT
  ],
  READ_HC_CLAIM_EXPANSE_REPORT: [
    PERMISSION.READ_HC_CLAIM_EXPANSE_REPORT
  ],
};

export const ROUTE_PMS_JSON = [
  {
    name: SECTION.DASHBOARD,
    key: 'DASHBOARD',
    icon: 'dashboard',
    children: [
      {
        name: SECTION.DASHBOARD_WORK,
        path: '/dashboard',
        pms_keys: [PERMISSION.READ_DASHBOARD],
      },
    ],
  },

  {
    name: SECTION.PRIMARY_WI_REQUEST,
    key: 'PRIMARY_WI_REQUEST',
    icon: 'corporate_fare',
    children: [
      {
        name: SECTION.PRIMARY_WI_REGISTRATION,
        path: '/accident-request/register',
        pms_keys: EXTENDED_ROUTE_PMS.PRIMARY_WI_REGISTRATION,
      },
      {
        name: SECTION.PRIMARY_WI_VALIDATION,
        path: '/accident-request/request',
        pms_keys: EXTENDED_ROUTE_PMS.PRIMARY_WI_VALIDATION,
      },
      {
        name: SECTION.MERGE_SEPARATE_VICTIM,
        path: '/accident-request/injuryReport',
        pms_keys: EXTENDED_ROUTE_PMS.MERGE_SEPARATE_VICTIM,
      },
    ],
  },
  {
    name: SECTION.INSPECTION_REQUEST_HEAD,
    key: 'INSPECTION_REQUEST',
    icon: 'corporate_fare',
    children: [
      {
        name: SECTION.INSPECTION_REQUEST,
        path: '/inspection/inspection-request',
        pms_keys: [
          PERMISSION.READ_INSPECT_REQ,
          PERMISSION.ALLOW_CREATE_INSPECT_REQ,
          PERMISSION.ALLOW_EDIT_INSPECT_REC,
          PERMISSION.ALLOW_DELETE_INSPECT_REQ,
        ],
      },
      {
        name: SECTION.INSPECTION_RESULT,
        path: '/inspection/inspection-result',
        pms_keys: [PERMISSION.READ_INSPECT_RESULT],
      },
    ],
  },
  {
    name: SECTION.TEMP_WI_VICTIM,
    key: 'TEMP_WI_VICTIM',
    icon: 'report',
    children: [
      {
        name: SECTION.TEMP_WI_VICTIM,
        path: '/temp-wi-victim',
        pms_keys: EXTENDED_ROUTE_PMS.TEMP_WI_VICTIM,
      },
    ],
  },
  {
    name: SECTION.RETREATMENT_REQUEST,
    key: 'RETREATMENT_REQUEST',
    icon: 'corporate_fare',
    children: [
      {
        name: SECTION.RETREATMENT_REQUEST,
        path: '/retreatment-injury',
        pms_keys: EXTENDED_ROUTE_PMS.RETREATMENT_REQUEST,
      },
    ],
  },
  {
    name: SECTION.MAIN_CLAIM_REQUEST,
    key: 'CLAIM_REQUEST',
    icon: 'corporate_fare',
    children: [
      {
        name: SECTION.CLAIM_TEMP_REQUEST,
        path: '/claim-report/temporaryclaimreport',
        pms_keys: EXTENDED_ROUTE_PMS.CLAIM_TEMP_REQUEST,
      },
      {
        name: SECTION.CLAIM_REQUEST,
        path: '/claim-report/receiveclaimreport',
        pms_keys: EXTENDED_ROUTE_PMS.CLAIM_REQUEST,
      },
    ],
  },
  {
    name: SECTION.DAILY_INCOME_RECORD,
    key: 'DAILY_INCOME_RECORD',
    icon: 'point_of_sale',
    children: [
      {
        name: SECTION.DAILY_INCOME_RECORD,
        path: '/victim-benifit',
        pms_keys: EXTENDED_ROUTE_PMS.DAILY_INCOME_RECORD,
      },
    ],
  },
  {
    name: SECTION.MEDICAL_CLAIM_REQUEST_MAIN_HOST,
    key: 'BENEFIT_CLAIM_REQUEST_HC',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.MEDICAL_CLAIM_REQUEST_DOC,
        path: '/claim-request-hc/health-care',
        pms_keys: EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST_HC,
      },
      {
        name: SECTION.TEMPORARY_CLAIM_REQUEST_DOC,
        path: '/claim-request-hc/temporary',
        pms_keys: EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST_HC,
      },

    ],
  },
  {
    name: SECTION.MEDICAL_CLAIM_REQUEST_MAIN,
    key: 'BENEFIT_CLAIM_REQUEST',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.MEDICAL_CLAIM_REQUEST,
        path: '/claim-request/health-care',
        pms_keys: EXTENDED_ROUTE_PMS.MEDICAL_CLAIM_REQUEST,
      },
      {
        name: SECTION.TEMPORARY_CLAIM_REQUEST,
        path: '/claim-request/temporary',
        pms_keys: EXTENDED_ROUTE_PMS.TEMPORARY_CLAIM_REQUEST,
      },
      {
        name: SECTION.PERMANENT_CLAIM_REQUEST,
        path: '/claim-request/permanent',
        pms_keys: EXTENDED_ROUTE_PMS.PERMANENT_CLAIM_REQUEST,
      },
      {
        name: SECTION.ALLOWANCE_CLAIM_REQUEST,
        path: '/claim-request/allowance',
        pms_keys: EXTENDED_ROUTE_PMS.ALLOWANCE_CLAIM_REQUEST,
      },
      {
        name: SECTION.SURVIVAL_CLAIM_REQUEST,
        path: '/claim-request/survival',
        pms_keys: EXTENDED_ROUTE_PMS.SURVIVAL_CLAIM_REQUEST,
      },
      {
        name: SECTION.CALCULATE_CLAIM_REQUEST,
        path: '/claim-request/survival',
        pms_keys: EXTENDED_ROUTE_PMS.CALCULATE_CLAIM_REQUEST,
      },
    ],
  },
  {
    name: SECTION.PRINT_INOF_HEAD,
    key: 'PRINT_INFO',
    icon: 'print_connect',
    children: [
      {
        name: SECTION.PRINT_INFO,
        path: '/print/print-info',
        pms_keys: EXTENDED_ROUTE_PMS.PRINT_INFO,
      },
      {
        name: SECTION.REQUEST_INFO,
        path: '/print/request-info',
        pms_keys: EXTENDED_ROUTE_PMS.REQUEST_INFO,
      },
    ],
  },
  {
    name: SECTION.MAIN_BENEFIT_APPROVAL,
    key: 'BENEFIT_APPROVAL',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.BENEFIT_APPROVAL_PRINT,
        path: '/benefit-approval/benefitprint',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_PRINT,
      },
      {
        name: SECTION.BENEFIT_APPROVAL_RECEIVER,
        path: '/benefit-approval/benefitapproval',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_CONFIRM,
      },
      // {
      //   name: SECTION.BENEFIT_APPROVAL_YEARLY_REPORT,
      //   path: '/benefit-approval/yearlyreport',
      //   pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_APPROVAL_YEARLY_REPORT,
      // },
    ],
  },
  {
    name: SECTION.MAIN_BENEFIT_NON_OCCUPATION,
    key: 'BENEFIT_NON_OCCUPATION',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.BENEFIT_NON_OCCUPATION_VICTIM,
        path: '/benefit-non-occupate/nonoccupatevictims',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_VICTIM,
      },
      {
        name: SECTION.BENEFIT_NON_OCCUPATION_MEDICAL,
        path: '/benefit-non-occupate/medicalbenefit',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_MEDICAL,
      },
      {
        name: SECTION.BENEFIT_NON_OCCUPATION_NO_REFUND,
        path: '/benefit-non-occupate/nonrefund',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_NO_REFUND,
      },
    ],
  },
  {
    name: SECTION.MAIN_BENEFIT_SHIPPING,
    key: 'BENEFIT_SHIPPING',
    icon: 'local_shipping',
    children: [
      {
        name: SECTION.BENEFIT_SHIPPING_FEE,
        path: '/shipping-benefit/shippingbenefit',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_FEE,
      },
      {
        name: SECTION.BENEFIT_SHIPPING,
        path: '/shipping-benefit/shippinglist',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING,
      },
      {
        name: SECTION.BENEFIT_SHIPPING_DECISION,
        path: '/shipping-benefit/shippinggive',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_SHIPPING_DECISION,
      },
    ],
  },
  {
    name: SECTION.WORK_ACTIVITY_REQUEST,
    key: 'WORK_ACTIVITY_REQUEST',
    icon: 'browser_updated',
    children: [
      {
        name: SECTION.WORK_ACTIVITY_REQUEST,
        path: '/work-activity',
        pms_keys: EXTENDED_ROUTE_PMS.WORK_ACTIVITY_REQUEST,
      },
    ],
  },
  {
    name: SECTION.MAIN_BENEFIT_HEALTH_CARE,
    key: 'BENEFIT_HEALTH_CARE',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.BENEFIT_HEALTH_CARE_RECEIVER,
        path: '/healthcare-benefit/healthreceivebenefit',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVER,
      },
      // {
      //   name: SECTION.BENEFIT_HEALTH_CARE_TRANSFER_DOC,
      //   path: '/healthcare-benefit/healthtransferdoc',
      //   pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_TRANSFER_DOC,
      // },
      // {
      //   name: SECTION.BENEFIT_HEALTH_CARE_RECEIVED,
      //   path: '/healthcare-benefit/receivedbenefit',
      //   pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_HEALTH_CARE_RECEIVED,
      // },
    ],
  },
  {
    name: SECTION.HC,
    key: 'CLAIM_REQUEST_HC_DOC',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.HC_EMERGENCY_CLAIM_REQUEST_DOC,
        path: '/hospital/emergency',
        pms_keys: EXTENDED_ROUTE_PMS.EMERGENCY_CLAIM_REQUEST_HC,
      },
      {
        name: SECTION.HC_SICK_LEAVE_CLAIM_REQUEST_DOC,
        path: '/hospital/sick-leave',
        pms_keys: EXTENDED_ROUTE_PMS.SICK_LEAVE_CLAIM_REQUEST_HC,
      },
    ],
  },
  {
    name: SECTION.HC,
    key: 'HC_CLAIM_REQUEST',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.HC_EMERGENCY_CLAIM_REQUEST,
        path: '/healthcare-calculation/emergency',
        pms_keys: EXTENDED_ROUTE_PMS.HC_EMERGENCY_CLAIM_REQUEST,
      },
      {
        name: SECTION.HC_MATERNITY_CLAIM_REQUEST,
        path: '/healthcare-calculation/maternity',
        pms_keys: EXTENDED_ROUTE_PMS.HC_MATERNITY_CLAIM_REQUEST,
      },
      {
        name: SECTION.HC_SICK_LEAVE_CLAIM_REQUEST,
        path: '/healthcare-calculation/sick-leave',
        pms_keys: EXTENDED_ROUTE_PMS.HC_SICK_LEAVE_CLAIM_REQUEST,
      },
      {
        name: SECTION.HC_TRANSPORT_CLAIM_REQUEST,
        path: '/healthcare-calculation/transportion',
        pms_keys: EXTENDED_ROUTE_PMS.HC_TRANSPORT_CLAIM_REQUEST,
      },
      {
        name: SECTION.HC_FUNERAL_CLAIM_REQUEST,
        path: '/healthcare-calculation/funeral',
        pms_keys: EXTENDED_ROUTE_PMS.HC_FUNERAL_CLAIM_REQUEST,
      },
    ],
  },
  {
    name: SECTION.BENEFIT_TABLE,
    key: 'BENEFIT_TABLE',
    icon: 'table_rows_narrow',
    children: [
      {
        name: SECTION.BENEFIT_TABLE,
        path: '/benefit-table',
        pms_keys: EXTENDED_ROUTE_PMS.BENEFIT_TABLE,
      },
    ],
  },
  {
    name: SECTION.HC_BENEFIT_NOTE,
    key: 'HC_BENEFIT_NOTE',
    icon: 'receipt_long',
    children: [
      {
        name: SECTION.NOTE,
        path: '/healthcare-note/note',
        pms_keys: EXTENDED_ROUTE_PMS.NOTE,
      },
      {
        name: SECTION.APPROVAL,
        path: '/healthcare-note/request',
        pms_keys: EXTENDED_ROUTE_PMS.APPROVAL,
      },
    ],
  },
  // {
  //     name: SECTION.SYSTEM_TRACKING,
  //     key: 'SYSTEM_TRACKING',
  //     icon: 'edit_notifications',
  //     children: [
  //         {
  //             name: SECTION.SYSTEM_TRACKING,
  //             path: '/tracking-system',
  //             pms_keys: EXTENDED_ROUTE_PMS.SYSTEM_TRACKING
  //         }
  //     ]
  // },
  {
    name: SECTION.NSSF_USER,
    key: 'NSSF_USER',
    icon: 'manage_accounts',
    children: [
      {
        name: SECTION.NSSF_USER,
        path: '/user-admin',
        pms_keys: EXTENDED_ROUTE_PMS.NSSF_USER,
      },
    ],
  },
  {
    name: SECTION.SETTING,
    key: 'SETTING',
    icon: 'settings',
    children: [
      {
        name: SECTION.SETTING,
        path: '/organization',
        pms_keys: EXTENDED_ROUTE_PMS.SETTING,
      },
    ],
  },
  {
    name: SECTION.ALL_REPORT,
    key: 'ALL_REPORT',
    icon: 'reports',
    children: [
      {
        name: SECTION.REPORT,
        path: '/report/wi/claim-wi',
        pms_keys: [
          PERMISSION.READ_WI_CLAIM_REQ_REPORT,

        ],
      },
      {
        name: SECTION.REPORT_ENTERPRISE,
        path: '/report/wi/officer-wi',
        pms_keys: [
          PERMISSION.READ_WI_CLAIM_AMOUNT_REPORT,

        ],
      },
      {
        name: SECTION.REPORT_CLAIM_HC,
        path: '/report/hc/hc-claim',
        pms_keys: [
          PERMISSION.READ_HC_CLAIM_REQ_REPORT,

        ],
      },
      {
        name: SECTION.REPORT_SUMMARY_TYPE,
        path: '/report/hc/summary',
        pms_keys: [
          PERMISSION.READ_HC_CLAIM_AMOUNT_REPORT,

        ],
      },
    ],
  },



];
