export enum STATUS {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  APPROVED = 'APPROVED',
  REVIEWING = 'REVIEWING',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED',
  ALLOCATE = 'ALLOCATE',
  ASSIGN = 'ASSIGN',
  SUSPENDED = 'SUSPENDED',
  REOPENED = 'REOPENED',
  CLOSED = 'CLOSED',
  DENIED = 'DENIED',
  PAID = 'PAID',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  ACCEPTED = 'ACCEPTED',


  HC_SUBMITTED = 'HC_SUBMITTED',
  HC_RETURNED = 'HC_RETURNED',
  HC_REJECTED = 'HC_REJECTED',
  HC_ACCEPTED = 'HC_ACCEPTED',
  BEN_HC_RETURNED = 'BEN_HC_RETURNED',

  LOCKED = 'LOCKED',
  FOLLOWED_UP = 'FOLLOWED_UP',
  COMPLETED = 'COMPLETED',
  TRANSFERRED = 'TRANSFERRED'
}

export enum MEMBER_STATUS {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
