import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { RequestService_v2 } from './request-v2.service';
import { ICommonSettingResponse } from '../models/responses/common-setting-response';
import { CommonSetting } from '../models/common-setting.model';
import { CommonResponse } from '../models/responses/common-response';
import { RequestService } from './request.service';

const Endpoint = {
  req_wi: '/primary-wi-request',
  req_hc: '/primary-hc-request',
  wi_victim: '/primary-wi-victim',
  wi_attachment: '/primary-wi-request/attachment',
  hc_attachment: '/primary-hc-request/attachment',
};

@Injectable({
  providedIn: 'root',
})
export class PrimaryInfoRequestService {
  constructor(private requestService: RequestService) { }

  // =========================================================
  // MAIN REQUEST
  // =========================================================

  getPrimaryWIInfoList(query: IRehaReqListBody): Observable<any> {
    return this.requestService.postJSON(`${Endpoint.req_wi}`, {
      data: query,
    }, true);
  }

  getPrimaryHCInfoList(query: IRehaReqListBody): Observable<any> {
    return this.requestService.postJSON(`${Endpoint.req_hc}`, {
      data: query,
    }, true);
  }

  removePrimaryWIInfo(id: string): Observable<any> {
    return this.requestService.deleteJSON<any>(
      `${Endpoint.req_wi}/${id}/delete`,
      {},
      true
    );
  }

  removePrimaryHCInfo(id: string): Observable<any> {
    return this.requestService.deleteJSON<any>(
      `${Endpoint.req_hc}/${id}/delete`,
      {},
      true
    );
  }

  getPrimaryWIVictimsByRequestId(request_id: string, loading = true): Observable<any> {
    return this.requestService.postJSON(`${Endpoint.wi_victim}`,
      {
        data: { request: request_id }
      }, {
      errSnackbar: true,
      loading: loading,
    });
  }

  getPrimaryWIInfoDetail(_id: string, loading = true): Observable<any> {
    return this.requestService.getJSON(`${Endpoint.req_wi}/${_id}`, {});
  }

  getPrimaryHCInfoDetail(_id: string, loading = true): Observable<any> {
    return this.requestService.getJSON(`${Endpoint.req_hc}/${_id}`, {});
  }

  saveRequestWI(data: IRehaReqSaveWI) {
    return this.requestService.postJSON(`${Endpoint.req_wi}/save`, {
      data: data,
      option: { is_loading: true },
    }, true);
  }

  saveRequestHC(data: IRehaReqSaveHC) {
    return this.requestService.postJSON(`${Endpoint.req_hc}/save`, {
      data: data,
      option: { is_loading: true },
    }, true);
  }

  submitWIRequest(id: string) {
    return this.requestService.postJSON(
      `${Endpoint.req_wi}/submit`,
      {data: { _id: id }},
      true
    );
  }

  submitHCRequest(id: string) {
    return this.requestService.postJSON(
      `${Endpoint.req_hc}/submit`,
      {data: { _id: id }},
      true
    );
  }

  createWITracking(
    status: string,
    data: IRehaReqTrackingBody
  ): Observable<any> {
    return this.requestService.postJSON(
      `${Endpoint.req_wi}/history/${status}`,
      {data: data},
      true
    );
  }
  createHCTracking(
    status: string,
    data: IRehaReqTrackingBody
  ): Observable<any> {
    return this.requestService.postJSON(
      `${Endpoint.req_hc}/history/${status}`,
      {data: data},
      true
    );
  }

  getWITracking(_id: string): Observable<any> {
    return this.requestService.getJSON(
      `${Endpoint.req_wi}/${_id}/history`,
      {}
    );
  }
  getHCTracking(_id: string): Observable<any> {
    return this.requestService.getJSON(
      `${Endpoint.req_hc}/${_id}/history`,
      {}
    );
  }

  // =========================================================
  // REQUEST ATTACHMENT
  // =========================================================
  uploadWIAttachments(files: FileList, type: any): Observable<CommonResponse<any>> {
    let data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('file', files[i]);
    }
    data.append('type', type);
    return this.requestService.postData(
      `${Endpoint.wi_attachment}`,
      {
        data: data,
        option: {
          is_loading: true,
        },
      }
    );
  }



  uploadHCAttachments(files: FileList, type: any): Observable<CommonResponse<any>> {
    let data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('file', files[i]);
    }
    data.append('type', type);
    return this.requestService.postData(
      `${Endpoint.hc_attachment}`,
      {
        data: data,
        option: {
          is_loading: true,
        },
      }
    );
  }

  getWIAttachment(_id: string): Observable<Blob> {
    return this.requestService.get(
      `${Endpoint.wi_attachment}/${_id}`,
      {
        data: null,
        option: {
          is_loading: true,
        },
      },
      'blob'
    );
  }

  getHCAttachment(_id: string): Observable<Blob> {
    return this.requestService.get(
      `${Endpoint.hc_attachment}/${_id}`,
      {
        data: null,
        option: {
          is_loading: true,
        },
      },
      'blob'
    );
  }

  removeWIAttachment(_id: string) {
    return this.requestService.deleteJSON(`${Endpoint.wi_attachment}/${_id}`, {}, true);
  }
  removeHCAttachment(_id: string) {
    return this.requestService.deleteJSON(`${Endpoint.hc_attachment}/${_id}`, {}, true);
  }
}

export interface IFollowUpListBody {
  search: any;
  request: string;
  status: string;
  page: number;
  count: number;
}

export interface IFollowUpSaveBody {
  request: string | null | undefined;
  current_injury_status: string | null | undefined;
  ben_feedback: string | null | undefined;
  conclusion: {
    description: string | null | undefined;
    contact_type: string | null | undefined;
    result_type: string | null | undefined;
  };
  date: string | null | undefined;
  documents: string[];
}

export interface IRehaReqListBody {
  search: string;
  ben_type?: string;
  service_type?: string;
  pro_id?: any;
  accident_date?: string;
  status?: string;
  page: number;
  count: number;
}

export interface IRehaReqTrackingBody {
  ref_id: string;
  to_department?: string;
  to_user?: string;
  description?: string;
  documents?: string[];
}

export interface IRehaReqSaveBasic {
  _id: string | null | undefined;
  first_name: string | null | undefined;
  last_name: string | null | undefined;
  gender: string | null | undefined;
  dob: string | null | undefined;
  nationality_id: number | null | undefined;
  ben_code: string | null | undefined;
  // ben_type: string | null | undefined,
  idcard: string | null | undefined;
  is_no_idcard: boolean | null | undefined;
  address: {
    house_no: string | null | undefined;
    street_no: string | null | undefined;
    group_no: string | null | undefined;
    village_id: number | null | undefined;
    commune_id: number | null | undefined;
    phone: string | null | undefined;
    email: string | null | undefined;
  };
  ent_id: number | null | undefined;
  ent_phone_number: string | null | undefined;
  // service_type: string | null | undefined,
  marital_status_type: string | null | undefined;
  documents: string[];
}

export interface IRehaReqSaveHC extends IRehaReqSaveBasic {
  accident_date: string | null | undefined;
  injury_cause: string | null | undefined;
  injury_desc: string | null | undefined;
  cause_type: string | null | undefined;
  hospital: string | null | undefined;
  // ben_treatment_status: string | null | undefined,
  home_care_duration: string | null | undefined;
  hos_check_out_date: string | null | undefined;
  doctor_appointment: Appointment;
  current_injury_status: string | null | undefined;
  injury_effect: string | null | undefined;
  prescription: any;
  prescription_desc: string | null | undefined;
  conclusion: {
    result: {
      result_type: string | null | undefined;
      date: string | null | undefined;
    };
    collect_info_num: number | null | undefined;
    contact_type: string | null | undefined;
    contact_date: string | null | undefined;
  };
  received_date: string | null | undefined;
}

export interface IRehaReqSaveWI extends IRehaReqSaveBasic {
  accident_date: string | null | undefined;
  injury_cause: string | null | undefined;
  injury_desc: string | null | undefined;
  cause_type: string | null | undefined;
  hospital: string | null | undefined;
  // ben_treatment_status: string | null | undefined,
  home_care_duration: string | null | undefined;
  hos_check_out_date: string | null | undefined;
  doctor_appointment: Appointment;
  current_injury_status: string | null | undefined;
  injury_effect: string | null | undefined;
  prescription: any;
  prescription_desc: string | null | undefined;
  injury_leave_allowance: StatusCheck;
  injury_status_check: StatusCheck;
  backing_to_work_check: StatusCheck;
  tend_to_work: StatusCheck;
  tend_to_learn_prof_skill: StatusCheck;
  admin_phone_number: string | null | undefined;
  feeling_effect_desc: string | null | undefined;
  relative_communicate_desc: string | null | undefined;
  social_communicate_desc: string | null | undefined;
  conclusion: {
    medical_service_desc: string | null | undefined;
    finding_job_service_desc: string | null | undefined;
    training_service_desc: string | null | undefined;
    social_service_desc: string | null | undefined;
    result: ResultTypeDate;
    collect_info_num: number | null | undefined;
    contact_type: string | null | undefined;
    contact_date: string | null | undefined;
  };
  received_date: string | null | undefined;
}

export interface ResultTypeDate {
  result_type: string | null | undefined;
  date: string | null | undefined;
}

export interface Appointment {
  option: any;
  date: string | null | undefined;
}

export interface StatusCheck {
  option: boolean | null | undefined;
  description: string | null | undefined;
}
